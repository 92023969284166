// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/routes/_index.tsx"
);
import.meta.hot.lastModified = "1721379226713.4478";
}
// REMIX HMR END

export { default, loader } from "./$.jsx";
